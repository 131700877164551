import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router'
import { EmployeeCompany } from '@interfaces/contract.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { AvatarModule } from 'primeng/avatar'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { ListboxModule } from 'primeng/listbox'

@Component({
  selector: 'app-user-companies',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    FormsModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    AvatarModule,
    ListboxModule,
  ],
  templateUrl: './user-companies.component.html',
  styleUrl: './user-companies.component.scss',
  host: {
    class: 'flexible',
  },
})
export class UserCompaniesComponent implements OnChanges {
  @Input() employee: Person | undefined
  @Input() employee_companies: EmployeeCompany[] = []
  @Input() hasCompanies = true

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    const employee = changes['employee']?.currentValue as Person
    if (!!employee) this.employee = employee

    const companies = changes['employee_companies']
      ?.currentValue as EmployeeCompany[]
    if (!!companies) this.employee_companies = companies

    const hasCompanies = changes['hasCompanies']?.currentValue as boolean
    if (hasCompanies !== undefined) this.hasCompanies = hasCompanies
  }

  redirect(company: string) {
    this.router.navigate(['/dashboard/report/per-user']).then(() => {
      if (!!this.employee)
        this.router.navigate([this.employee.cuenta._id, company], {
          relativeTo: this.route.parent,
        })
    })
  }
}
