import { TitleCasePipe } from '@angular/common'
import { AfterViewChecked, Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { ApiCompanies } from '@api/api.companies'
import {
  Company,
  CompanyType,
  NewCompany,
} from '@interfaces/companies.interface'
import { pTableColumn, pTableCompany } from '@interfaces/p-table.interface'
import { ToastService } from '@services/toast.service'
import { ConfirmationService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { SidebarModule } from 'primeng/sidebar'
import { Table, TableModule } from 'primeng/table'
import { ApiService } from 'src/app/services/api.service'
import { CompaniesService } from 'src/app/services/companies.service'
import { TABLE_FILE_NAMES } from 'src/app/utils/p-table-names'

@Component({
  selector: 'app-companies',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
    ConfirmDialogModule,
    ButtonModule,
    TitleCasePipe,
    SidebarModule,
    DropdownModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './companies.component.html',
  styleUrl: './companies.component.scss',
  host: {
    class: 'flexible',
  },
})
export class CompaniesComponent implements OnInit, AfterViewChecked {
  // MARK: class members
  // table
  table_name = TABLE_FILE_NAMES['companies']
  searchDate: Date | undefined
  columns: pTableColumn[] = [
    { field: 'nombre', header: 'empresa' },
    { field: 'tipo_empresa', header: 'tipo' },
    { field: 'supervisor', header: 'supervisor' },
    { field: 'acciones', header: 'acciones' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableCompany[] = []

  sidebarVisible = false
  companies: Company[] = []
  new_company: NewCompany = {
    nombre: '',
    supervisor: '',
    tipo_empresa: {
      _id: -1,
      nombre: '',
    },
  }
  company_types: CompanyType[] = []

  constructor(
    private router: Router,
    private srvAPI: ApiService,
    private srvCompanies: CompaniesService,
    private confirmationService: ConfirmationService,
    private srvToast: ToastService,
  ) {
    this.srvCompanies.companies$.subscribe((companies: any) => {
      this.companies = companies['active']
      this.loadTable()
    })
  }

  ngOnInit() {
    this.srvCompanies.loadData()
  }

  ngAfterViewChecked(): void {
    this.addButtonToTableFooter()
  }

  addButtonToTableFooter() {
    if (!!document.getElementById('paginator-container')) return

    const footer = document.querySelector('p-paginator div.p-paginator')
    if (footer) {
      const nodo = document.createElement('div')
      nodo.setAttribute('id', 'paginator-container')
      nodo.setAttribute(
        'class',
        'flex flex-1 items-center justify-center absolute inset-0',
      )
      const sibling = footer.querySelector('button[aria-label="First Page"]')
      if (sibling) footer.insertBefore(nodo, sibling)

      const div = document.getElementById('paginator-container')
      if (div) {
        const el = `
          <button
            id="paginator-button"
            class="flex items-center justify-around text-nowrap p-2 w-[120px] bg-transparent font-bold text-[1rem] rounded-[2rem] text-[#64748b] border-[1px] border-[#64748b]"
            type="button"
          >Ver archivados</button>
          `
        div.insertAdjacentHTML('afterbegin', el)
        const button = document.getElementById('paginator-button')
        if (button) {
          const icon = '<i class="pi pi-inbox text-lg"></i>'
          button.insertAdjacentHTML('afterbegin', icon)
          button.addEventListener('click', () => {
            this.goToArchived()
          })
        }
      }
    }
  }

  loadTable() {
    this.pTableData = this.companies.map((item) => {
      return {
        _id: item._id,
        nombre: item.nombre,
        tipo_empresa: item.tipo_empresa.nombre,
        supervisor: item.supervisor,
      } as pTableCompany
    })
  }

  preExport() {
    const table_companies: pTableCompany[] = []
    this.companies.forEach((item) => {
      item.departamentos.forEach((dep) => {
        if (dep.archivado === 0)
          table_companies.push({
            _id: item._id,
            nombre: item.nombre,
            tipo_empresa: item.tipo_empresa.nombre,
            supervisor: item.supervisor,
            departamento: dep.nombre,
            plazas: dep.plazas,
          } as pTableCompany)
      })
    })
    this.pTableData = table_companies
  }

  async exportToCSV(pTable: Table) {
    this.preExport()

    const last = this.columns.pop()
    const first = this.columns.shift()

    if (first) {
      const columns: pTableColumn[] = [
        first,
        { field: 'departamento', header: 'departamento' },
      ]
      this.columns.unshift(...columns)

      await new Promise((f) => setTimeout(f, 200))
      pTable.exportCSV()

      this.columns.splice(0, columns.length)
      this.columns.unshift(first)
      if (last) this.columns.push(last)
    }

    this.loadTable()
  }
  goToArchived() {
    this.router.navigateByUrl('/dashboard/companies/archived')
  }

  viewCompanyInfo(companyName: string) {
    this.router.navigateByUrl(`/dashboard/companies/${companyName}`)
  }

  archiveCompanyInfo(event: Event, company: Company) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        'Esta acción desactivará la empresa pero <br/> conservará todos sus registros asociados',
      header: 'Archivar la Empresa',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Archivar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .patch(`${ApiCompanies.archivar}/${company._id}/1`, {})
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se archivó la empresa',
              })
              this.srvCompanies.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `${error.error.statusCode} ${error.error.error}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }

  openCreateCompany() {
    this.srvAPI.get(ApiCompanies.obtenerTipos).subscribe((response: any) => {
      this.company_types = response['data'] as CompanyType[]
    })
    this.sidebarVisible = true
  }

  createCompany() {
    // clean data
    const company = {
      nombre: this.new_company.nombre,
      supervisor: this.new_company.supervisor,
      id_tipo_empresa: this.new_company.tipo_empresa._id,
    }

    this.srvAPI.post(ApiCompanies.crear, company).subscribe({
      next: (response: any) => {
        this.srvToast.success({
          title: response.status,
          message: 'Se creó la empresa',
        })
        this.srvCompanies.loadData()
      },
      error: (error) => {
        this.srvToast.error({
          title: `${error.error.statusCode} ${error.error.error}`,
          message: error.error.message,
        })
      },
    })
  }
}
