<div class="flex items-center justify-center py-3 mb-5">
  <img
    src="logo-talentrack.svg"
    alt="logo talentack"
    class="w-[40px] h-[40px]"
  />
  <h1
    class="ml-2 text-4xl -mb-3 tracking-tighter font-arial-narrow text-red-color text-[30px]"
  >
    TalenTrack
  </h1>
</div>

<p-panelMenu [model]="items" styleClass="w-full">
  <ng-template pTemplate="item" let-item>
    @if (item.route) {
    <a
      class="flex items-center flex-1 p-3 cursor-pointer hover:bg-grey-hover rounded-[10px]"
      [routerLink]="item.route"
      routerLinkActive="hover:bg-white bg-white border-l-4 border-sky-color text-sky-color font-bold active"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      <span [class]="item.icon"></span>
      <span class="ml-2 text-color">{{ item.label }}</span>
    </a>
    } @else {
    <span
      class="flex items-center flex-1 p-3 cursor-pointer hover:bg-grey-hover rounded-[10px]"
    >
      <span [class]="item.icon"></span>
      <span class="ml-2">{{ item.label }}</span>
      @if (item.items) {
      <i
        class="pi {{
          !!!item.expanded ? 'pi-angle-right' : 'pi-angle-down'
        }} ml-auto text-sky-color"
      ></i>
      }
    </span>
    }
  </ng-template>
</p-panelMenu>

<div class="flex flex-1"></div>
<p-button
  styleClass="w-full flex items-center text-lg text-start mb-10 hover:border-l-4 hover:border-red-color text-red-color font-bold rounded-[10px]"
  label="Cerrar sesión"
  icon="pi pi-sign-out"
  severity="danger"
  [text]="true"
  (onClick)="logout()"
/>
