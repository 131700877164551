import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ApiAccounting } from '@api/api.accounting'
import { ApiContract } from '@api/api.contract'
import { Income, NewIncome } from '@interfaces/accounting.interface'
import { EmployeeCompany } from '@interfaces/contract.interface'
import { pTableColumn, pTableIncome } from '@interfaces/p-table.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { AccountingService } from '@services/accounting.service'
import { ApiService } from '@services/api.service'
import { PersonnelManagementService } from '@services/personnel-management.service'
import { ToastService } from '@services/toast.service'
import { ConfirmationService } from 'primeng/api'
import { AvatarModule } from 'primeng/avatar'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ChipModule } from 'primeng/chip'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputTextModule } from 'primeng/inputtext'
import { ListboxModule } from 'primeng/listbox'
import { SidebarModule } from 'primeng/sidebar'
import { Table, TableModule } from 'primeng/table'
import { TABLE_FILE_NAMES } from 'src/app/utils/p-table-names'
import { PadNumberPipe } from 'src/app/utils/pad-number.pipe'

@Component({
  selector: 'app-income',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    InputNumberModule,
    TableModule,
    TitleCasePipe,
    ConfirmDialogModule,
    ButtonModule,
    DialogModule,
    AvatarModule,
    ChipModule,
    SidebarModule,
    ListboxModule,
    DropdownModule,
    TitleCasePipe,
    DatePipe,
    CurrencyPipe,
    PadNumberPipe,
  ],
  providers: [ConfirmationService],
  templateUrl: './income.component.html',
  styleUrl: './income.component.scss',
  host: {
    class: 'flexible',
  },
})
export class IncomeComponent implements OnInit {
  // MARK: class members
  // table
  table_name = TABLE_FILE_NAMES['income']
  searchDate: Date | undefined
  columns: pTableColumn[] = [
    { field: '_id', header: 'código' },
    { field: 'usuario', header: 'usuario' },
    { field: 'empresa', header: 'empresa' },
    { field: 'concepto', header: 'concepto' },
    { field: 'monto', header: 'monto' },
    { field: 'dias', header: 'dias' },
    { field: 'created_at', header: 'fecha solicitud' },
    { field: 'acciones', header: 'acciones' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableIncome[] = []

  income: Income[] = []
  incomeToDisplay: Income | undefined
  new_income: NewIncome = {
    created_at: '',
    cuenta_id: -1,
    empresa_id: -1,
    monto: 0,
    dias: 0,
    concepto: '',
  }

  displayVisible = false
  sidebarVisible = false
  maxDays = 0
  today = Intl.DateTimeFormat('es-EC', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date())
  employees: Person[] = []
  employeesFiltered: { value: number; label: string }[] = []
  employeeInput = ''
  employee_companies: EmployeeCompany[] = []
  companiesFiltered: EmployeeCompany[] = []
  companyInput = ''

  constructor(
    private srvAPI: ApiService,
    private srvAccounting: AccountingService,
    private srvPersonnelManagement: PersonnelManagementService,
    private confirmationService: ConfirmationService,
    private srvToast: ToastService,
  ) {
    this.srvAccounting.income$.subscribe((income: Income[]) => {
      // 0 recurrentes - 1 fijos
      this.income = income.filter((item) => item.forma !== 0)
      this.loadTable()
    })
    this.srvPersonnelManagement.employees$.subscribe((employees: any) => {
      this.employees = employees['active']
    })
  }

  ngOnInit() {
    this.srvAccounting.loadIncome()
    const today = new Date()
    today.setMonth(today.getMonth() + 1)
    today.setDate(0)
    this.maxDays = today.getDate()
  }

  loadTable() {
    this.pTableData = this.income.map((item) => {
      return {
        _id: item._id,
        usuario: `${item.contrato.cuenta.persona.nombre} ${item.contrato.cuenta.persona.apellido}`,
        empresa: item.contrato.empresa.nombre,
        concepto: item.concepto,
        monto: item.monto,
        dias: item.dias,
        created_at: new Date(item.created_at),
      } as pTableIncome
    })
  }

  filterByDate(value: Date, field: keyof pTableIncome) {
    this.loadTable()
    this.pTableData = this.pTableData.filter((record) => {
      const recordDate = new Date(record[field])
      return recordDate.toDateString() === value.toDateString()
    })
  }

  exportToCSV(pTable: Table) {
    const last = this.columns.pop()
    this.pTableData.forEach((record) => {
      record.created_at = Intl.DateTimeFormat('es-EC', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(record.created_at as Date)
    })
    pTable.exportCSV()
    if (last) this.columns.push(last)
    this.loadTable()
  }

  openCreateIncome() {
    this.srvPersonnelManagement.loadData()
    this.new_income.created_at = new Date().toISOString()
    this.sidebarVisible = true
  }

  onEmployeeInput(event: Event) {
    const input = (event.target as HTMLInputElement).value
    if (input === '') this.employeesFiltered = []
    else
      this.employeesFiltered = this.employees
        .filter((employee) =>
          `${employee.nombre} ${employee.apellido}`
            .toLowerCase()
            .includes(input.trim().toLowerCase()),
        )
        .map((employee) => {
          return {
            value: employee._id,
            label: `${employee.nombre} ${employee.apellido}`,
          }
        })
  }
  changeEmployee(employee_id: number) {
    const employee = this.employees.find(
      (employee) => employee._id === employee_id,
    )

    if (!!employee) this.new_income.cuenta_id = employee.cuenta._id
    this.employeeInput = `${employee?.nombre} ${employee?.apellido}` || ''
    this.employeesFiltered = []

    this.srvAPI
      .get(
        `${ApiContract.obtenerEmpresasContratoDeEmpleado}/${employee?.cuenta._id}`,
      )
      .subscribe((response: any) => {
        this.employee_companies = response['data'][
          'empresas'
        ] as EmployeeCompany[]
        this.companiesFiltered = this.employee_companies
        this.companyInput = ''
      })
  }

  onCompanyInput(event: Event) {
    const input = (event.target as HTMLInputElement).value
    this.companiesFiltered = this.employee_companies.filter((company) =>
      company.nombre.toLowerCase().includes(input.trim().toLowerCase()),
    )
  }
  changeCompany(company_id: number) {
    const company = this.employee_companies.find(
      (company) => company._id === company_id,
    )
    this.new_income.empresa_id = company?._id || -1
    this.companyInput = company?.nombre || ''
    this.companiesFiltered = []
  }

  createIncome() {
    if (this.new_income.dias <= 0) {
      this.srvToast.error({
        title: 'Error 400',
        message: 'El campo de días es obligatorio',
      })
      return
    }

    this.srvAPI
      .post(
        `${ApiAccounting.crearIngreso}/${this.new_income.empresa_id}/${this.new_income.cuenta_id}`,
        this.new_income,
      )
      .subscribe({
        next: (response: any) => {
          this.srvToast.success({
            title: response.status,
            message: 'Se creó el registro',
          })
          this.srvAccounting.loadIncome()
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }

  viewIncome(income: Income) {
    this.incomeToDisplay = this.income.find((item) => item._id === income._id)
    if (!!!this.incomeToDisplay) return

    this.incomeToDisplay.pago = income.monto
    this.incomeToDisplay.updated_at = new Date().toISOString().split('T')[0]
    this.displayVisible = true
  }

  deleteIncome(event: Event, income: Income) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        '¿Esta seguro que desea eliminar el registro? <br/> Esta acción no puede deshacerse',
      header: 'Eliminar ingreso',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Eliminar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .delete(`${ApiAccounting.eliminarRegistroPorID}/${income._id}`)
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se eliminó el registro',
              })
              this.srvAccounting.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `${error.error.statusCode} ${error.error.error}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }
}
