<div class="flex items-center justify-center flex-1">
  <!-- NAV Mobile & Desktop -->
  <p-sidebar
    class="block md:px-2 md:hidden nav-sidebar bg-grey-color"
    [modal]="false"
    [(visible)]="sidebarVisible"
  >
    <app-nav class="flex flex-col flex-1" [items]="routes"></app-nav>
  </p-sidebar>

  <nav
    class="flex-col hidden px-2 min-w-[14%] h-screen md:flex bg-grey-color"
  >
    <app-nav class="flex flex-col flex-1" [items]="routes"></app-nav>
  </nav>

  <section class="h-screen flex flex-col flex-1 md:max-w-[86%]">
    <p-toolbar
      class="w-full"
      styleClass="py-1 bg-[transparent] rounded-none border-l-0"
    >
      <div class="block p-toolbar-group-start md:hidden">
        <p-button
          icon="pi pi-bars"
          (onClick)="sidebarVisible = !sidebarVisible"
        />
      </div>
      <div class="p-toolbar-group-center">
        <span
          [class]="
            (activeRoute && activeRoute[activeRoute.length - 1].icon) || ''
          "
        ></span>
        <span class="ml-2">{{
          (activeRoute && activeRoute[activeRoute.length - 1].label) || ''
        }}</span>
      </div>
      <div class="p-toolbar-group-end">
        <p-chip
          styleClass="px-5 flex justify-between items-center bg-aqua-color"
          role="button"
          (click)="changeAccountModal(true)"
        >
          <p-avatar
            [label]="user.name[0]"
            styleClass="mr-2 bg-[gainsboro] rounded-full"
            size="xlarge"
          />
          <p class="hidden md:block">
            <span>{{ user.name }}</span
            ><br />
            <span class="text-sm capitalize">{{ user.role }}</span>
          </p>
        </p-chip>
      </div>
    </p-toolbar>

    <p-breadcrumb
      class="hidden p-menu-panel md:block"
      styleClass="w-full bg-[transparent] border-none py-0 lowercase text-sm"
      [model]="activeRoute && activeRoute"
      [home]="home"
    >
      <ng-template pTemplate="routes" let-item>
        @if (item.route) {
        <a
          class="flex items-center px-3 py-2 cursor-pointer text-color"
          [routerLink]="item.route"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span [class]="item.icon"></span>
          <span class="ml-2 text-color">{{ item.label }}</span>
        </a>
        } @else {
        <span class="flex items-center px-3 py-2 cursor-pointer text-color">
          <span [class]="item.icon"></span>
          <span class="ml-2">{{ item.label }}</span>
        </span>
        }
      </ng-template>
    </p-breadcrumb>

    <router-outlet></router-outlet>
    <router-outlet name="employee-contract"></router-outlet>
  </section>
</div>

@defer {
<app-account
  [visibilityModal]="visibilityModal"
  (changeAccountModal)="changeAccountModal($event)"
></app-account>
}
