<p-dialog
  header="Registro de horas"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '350px' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
>
  <div class="flex flex-col space-y-3">
    <div class="flex w-full p-2 bg-blue-300 rounded-lg">
      <div class="w-[50px] h-[50px]">
        <img
          [src]="
            persona ? persona['contrato']['cuenta']['persona']['url_image'] : ''
          "
          class="w-[50px] h-[50px] rounded-[50%]"
          alt=""
        />
      </div>
      <div class="flex items-center flex-1 pl-5 text-black">
        {{
          persona
            ? persona['contrato']['cuenta']['persona']['nombre'] +
              persona['contrato']['cuenta']['persona']['apellido']
            : ''
        }}
        <br />
        {{ persona ? persona['contrato']['cuenta']['usuario'] : '' }}
      </div>
    </div>
    <div class="flex w-full">
      <div class="flex flex-1 space-x-2">
        <img
          src="/icons/corporate_fare_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
          alt=""
          class="w-[20px]"
        /><b>Empresa:</b>
      </div>
      <div class="flex justify-end flex-1">
        {{ persona ? persona['contrato']['empresa']['nombre'] : '' }}
      </div>
    </div>
    <div class="flex w-full">
      <div class="flex flex-1 space-x-2">
        <img
          src="/icons/type=department_V,fill=false.svg"
          alt=""
          class="w-[20px]"
        /><b>Departamento:</b>
      </div>
      <div class="flex justify-end flex-1">
        {{ persona ? persona['contrato']['departamento']['nombre'] : '' }}
      </div>
    </div>
    <div class="flex w-full">
      <div class="flex flex-1 space-x-2">
        <img
          src="/icons/calendar_month_24dp_666666_FILL0_wght400_GRAD0_opsz24.svg"
          alt=""
          class="w-[20px]"
        /><b>Solicitud:</b>
      </div>
      <div class="flex justify-end flex-1">
        {{
          persona
            ? (persona['contrato']['created_at'] | date : 'dd-MM-yyyy')
            : ''
        }}
      </div>
    </div>
    <div class="bg-[#f7f7f7] py-2 mt-3 font-bold">Tiempos</div>
    <div class="flex w-full">
      <div class="flex flex-1 space-x-2">
        <img
          src="/icons/type=check-in_time_V,fill=false.svg"
          alt=""
          class="w-[20px]"
        /><b>Entrada:</b>
      </div>
      <div class="flex justify-end flex-1">
        {{ persona ? persona['entrada'] : '' }}
      </div>
    </div>
    <div class="flex w-full">
      <div class="flex flex-1 space-x-2">
        <img
          src="/icons/type=check-in_time_V,fill=false.svg"
          alt=""
          class="w-[20px]"
        /><b>Salida:</b>
      </div>
      <div class="flex justify-end flex-1">
        {{ persona ? persona['salida'] : '' }}
      </div>
    </div>
    <div class="flex w-full">
      <div class="flex flex-1 space-x-2">
        <img
          src="/icons/schedule_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
          alt=""
          class="w-[20px]"
        /><b>Tiempo laborado:</b>
      </div>
      <div class="flex justify-end flex-1">
        {{ persona ? persona['cantidad'] : '' }}
      </div>
    </div>
    <div class="flex w-full">
      <div class="flex flex-1 space-x-2">
        <img
          src="/icons/coffee_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
          alt=""
          class="w-[20px]"
        /><b>Descanso:</b>
      </div>
      <div class="flex justify-end flex-1">
        {{ persona ? persona['contrato']['descanso'] : '' }}
      </div>
    </div>
    <div class="flex w-full">
      <div class="flex flex-1 space-x-2">
        <img
          src="/icons/type=hours_worked_V,fill=false.svg"
          alt=""
          class="w-[20px]"
        /><b>Tiempo Marcado:</b>
      </div>
      <div class="flex justify-end flex-1">
        {{
          persona
            ? restarMinutos(
                persona['cantidad'] || '00:00:00',
                persona['contrato']['descanso'],
                { modoReloj: false, formatoNegativo: false }
              )
            : ''
        }}
      </div>
    </div>
  </div>
</p-dialog>
