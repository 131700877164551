import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'padNumber',
  standalone: true,
})
export class PadNumberPipe implements PipeTransform {
  transform(value: number | null | undefined, length = 10): string {
    if (!!!value) return ''

    const num = `${value}`
    const rest = length - num.length
    return Array(rest).fill('0').join('') + num
  }
}
