import { TitleCasePipe } from '@angular/common'
import { AfterViewChecked, Component, OnInit } from '@angular/core'
import {
  getDownloadURL,
  ref,
  Storage,
  uploadBytes,
} from '@angular/fire/storage'
import { FormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { ApiPersonnelManagement } from '@api/api.personnel-management'
import { pTableColumn, pTableEmployee } from '@interfaces/p-table.interface'
import {
  Account,
  NewAccount,
  Person,
} from '@interfaces/personnel-management.interface'
import { PersonnelManagementService } from '@services/personnel-management.service'
import { ToastService } from '@services/toast.service'
import { ConfirmationService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ChipModule } from 'primeng/chip'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { FileUploadModule } from 'primeng/fileupload'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { SidebarModule } from 'primeng/sidebar'
import { Table, TableModule } from 'primeng/table'
import { catchError, forkJoin, mergeMap, Observable, of } from 'rxjs'
import { ApiService } from 'src/app/services/api.service'
import { TABLE_FILE_NAMES } from 'src/app/utils/p-table-names'

@Component({
  selector: 'app-personnel-management',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
    ConfirmDialogModule,
    ButtonModule,
    ChipModule,
    TitleCasePipe,
    SidebarModule,
    FileUploadModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './personnel-management.component.html',
  styleUrl: './personnel-management.component.scss',
  host: {
    class: 'flexible',
  },
})
export class PersonnelManagementComponent implements OnInit, AfterViewChecked {
  // MARK: class members
  // table
  table_name = TABLE_FILE_NAMES['personnel_management']
  columns: pTableColumn[] = [
    { field: 'usuario', header: 'identificación' },
    { field: 'nombre', header: 'usuario' },
    { field: 'correo', header: 'correo' },
    { field: 'telefono', header: 'teléfono' },
    { field: 'especializaciones', header: 'especialización' },
    { field: 'acciones', header: 'acciones' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableEmployee[] = []

  employees: Person[] = []
  employee: Person | undefined
  sidebarVisible = false
  isCreateInProgress = false
  new_employee: NewAccount = {
    nombre: '',
    apellido: '',
    usuario: '',
    correo: '',
    telefono: '',
    especialidades: [],
  }

  fileImage: any = ''
  filePasaporte: any = ''
  previewImage: string | undefined = undefined
  previewPasaporte: string | undefined = undefined

  constructor(
    private router: Router,
    private srvAPI: ApiService,
    private srvPersonnelManagement: PersonnelManagementService,
    private confirmationService: ConfirmationService,
    private storage: Storage,
    private srvToast: ToastService,
  ) {
    this.srvPersonnelManagement.employees$.subscribe((employees: any) => {
      this.employees = employees['active']
      this.loadTable()

      if (this.employee?.cuenta.usuario) {
        this.employee = this.employees.find(
          (employee) =>
            employee.cuenta.usuario === this.employee?.cuenta.usuario,
        )
        if (!!!this.employee) return

        this.viewEmployeeInfo(this.employee.cuenta._id)
        this.employee = undefined
      }
    })
  }

  ngOnInit() {
    this.srvPersonnelManagement.loadData()
  }

  ngAfterViewChecked(): void {
    this.addButtonToTableFooter()
  }

  addButtonToTableFooter() {
    if (!!document.getElementById('paginator-container')) return

    const footer = document.querySelector('p-paginator div.p-paginator')
    if (footer) {
      const nodo = document.createElement('div')
      nodo.setAttribute('id', 'paginator-container')
      nodo.setAttribute(
        'class',
        'flex flex-1 items-center justify-center absolute inset-0',
      )
      const sibling = footer.querySelector('button[aria-label="First Page"]')
      if (sibling) footer.insertBefore(nodo, sibling)

      const div = document.getElementById('paginator-container')
      if (div) {
        const el = `
          <button
            id="paginator-button"
            class="flex items-center justify-around text-nowrap p-2 w-[120px] bg-transparent font-bold text-[1rem] rounded-[2rem] text-[#64748b] border-[1px] border-[#64748b]"
            type="button"
          >Ver archivados</button>
          `
        div.insertAdjacentHTML('afterbegin', el)
        const button = document.getElementById('paginator-button')
        if (button) {
          const icon = '<i class="pi pi-inbox text-lg"></i>'
          button.insertAdjacentHTML('afterbegin', icon)
          button.addEventListener('click', () => {
            this.goToArchived()
          })
        }
      }
    }
  }

  loadTable() {
    this.pTableData = this.employees.map((item) => {
      return {
        _id: item.cuenta._id,
        usuario: item.cuenta.usuario,
        nombre: `${item.nombre} ${item.apellido}`,
        correo: item.correo,
        telefono: item.telefono,
        especializaciones: item.personaEspecialidades.map(
          (esp) => esp.especialidad.nombre,
        ),
      } as pTableEmployee
    })
  }

  exportToCSV(pTable: Table) {
    const last = this.columns.pop()
    pTable.exportCSV()
    if (last) this.columns.push(last)
  }

  goToArchived() {
    this.router.navigateByUrl('/dashboard/personnel-management/archived')
  }

  viewEmployeeInfo(employeeID: number) {
    this.router.navigateByUrl(`dashboard/personnel-management/${employeeID}`)
  }

  archiveEmployeeInfo(event: Event, employeeID: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        'Esta acción desactivará al empleado pero <br/> conservará todos sus registros asociados',
      header: 'Archivar el Empleado',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Archivar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .patch(`${ApiPersonnelManagement.archivar}/${employeeID}/1`, {})
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se archivó el empleado',
              })
              this.srvPersonnelManagement.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `${error.error.statusCode} ${error.error.error}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }

  openCreateEmployee() {
    this.sidebarVisible = true
  }

  uploadFileImage(event: any) {
    this.fileImage = event.target.files[0]
    this.previewImage = URL.createObjectURL(this.fileImage)
  }

  uploadFilePasaporte(event: any) {
    this.filePasaporte = event.target.files[0]
    this.previewPasaporte = URL.createObjectURL(this.filePasaporte)
  }

  onFocusInfo() {
    this.srvToast.info({
      title: 'Agregar presionando Enter',
      message: 'Nueva especialidad',
    })
  }

  onEnterAddSpecialty(event: Event) {
    event.preventDefault()
    const input = event.target as HTMLInputElement
    if (!!!input.value || input.value === '') {
      this.srvToast.info({
        title: 'Entrada no válida',
        message: 'Ingrese una especialidad',
      })
      return
    }

    this.new_employee.especialidades.push(input.value.trim())
    input.value = ''
  }

  removeSpecialty(specialty: string) {
    const index = this.new_employee.especialidades.indexOf(specialty)
    if (index !== -1) this.new_employee.especialidades.splice(index, 1)
  }

  createEmployee() {
    this.isCreateInProgress = true
    if (this.new_employee.correo?.trim() === '') this.new_employee.correo = null

    // 1. create new employee
    this.srvAPI
      .post(ApiPersonnelManagement.crear, this.new_employee)
      .pipe(
        // 2. chain creation to update images and specialties with employee from response
        mergeMap(async (response) => {
          if (
            !!!this.fileImage &&
            !!!this.filePasaporte &&
            !!!this.new_employee.especialidades
          )
            // if nothing to update return employee only
            return of(response)

          const employee = (response as { data: Account }).data
          const persona_id = employee.persona?._id
          const usuario = employee.usuario

          // image upload
          try {
            if (!!this.fileImage) {
              const storageRefImage = ref(
                this.storage,
                `persona/${persona_id}/perfil.${
                  this.fileImage.name.split('.').pop() || ''
                }`,
              )
              const snapshotImage = await uploadBytes(
                storageRefImage,
                this.fileImage,
              )
              const downloadURLImage = await getDownloadURL(snapshotImage.ref)
              const urlObjImage: URL | undefined = new URL(downloadURLImage)
              this.new_employee.url_image = urlObjImage.href
            }

            if (!!this.filePasaporte) {
              const storageRefPasaporte = ref(
                this.storage,
                `persona/${persona_id}/pasaporte.${
                  this.filePasaporte.name.split('.').pop() || ''
                }`,
              )
              const snapshotPasaporte = await uploadBytes(
                storageRefPasaporte,
                this.filePasaporte,
              )
              const downloadURLPasaporte = await getDownloadURL(
                snapshotPasaporte.ref,
              )
              const urlObjPasaporte: URL | undefined = new URL(
                downloadURLPasaporte,
              )
              this.new_employee.url_pasaporte = urlObjPasaporte.href
            }
          } catch (error) {
          } finally {
            // 3. merge both requests: update images and specialties
            const especialidades = this.new_employee.especialidades

            return forkJoin({
              actualizar_persona:
                !!this.fileImage || !!this.filePasaporte
                  ? this.srvAPI
                      .patch(
                        `${ApiPersonnelManagement.actualizar_persona}/${usuario}`,
                        this.new_employee,
                      )
                      .pipe(catchError(() => of(null)))
                  : of(false),

              asignar_especialidades: !!this.new_employee.especialidades
                ? this.srvAPI
                    .post(
                      `${ApiPersonnelManagement.asignarEspecialidades}/${persona_id}`,
                      { especialidades },
                    )
                    .pipe(catchError(() => of(null)))
                : of(false),
            })
          }
        }),
      )
      .subscribe({
        next: (
          response: Observable<
            | {
                actualizar_persona: any | null
                asignar_especialidades: any | null
              }
            | any
          >,
        ) => {
          try {
            response.subscribe((sub: any) => {
              if (
                'actualizar_persona' in sub &&
                sub.actualizar_persona === null
              )
                this.srvToast.error({
                  title: 'Error 400',
                  message: 'Error al cargar los archivos',
                })

              if (
                'asignar_especialidades' in sub &&
                sub.asignar_especialidades === null
              )
                this.srvToast.error({
                  title: 'Error 400',
                  message: 'Error al actualizar especialidades',
                })

              this.srvToast.success({
                title: '200',
                message: 'Se creó el empleado',
              })

              // routing
              this.employee = {
                _id: 0,
                nombre: '',
                apellido: '',
                sexo: '',
                correo: '',
                telefono: '',
                nacionalidad: '',
                url_pasaporte: '',
                url_image: '',
                fecha_nacimiento: '',
                cuenta: {
                  _id: 0,
                  clave: '',
                  usuario: this.new_employee.usuario,
                  archivado: 0,
                },
                personaEspecialidades: [],
              }
              this.srvPersonnelManagement.loadData()
            })
          } catch (error) {
            this.srvToast.error({
              title: 'Error',
              message: 'Error desconocido',
            })
            this.isCreateInProgress = false
          }
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
          this.isCreateInProgress = false
        },
      })
  }
}
