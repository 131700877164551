import { HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { AuthData } from '@interfaces/auth.interface'
import { AuthService } from '@services/auth.service'

const isTokenExpired = (token: string) => {
  const expiry: number = JSON.parse(atob(token.split('.')[1]))?.exp || -1
  if (expiry === -1) return true

  return Math.floor(Date.now() / 1000) >= expiry
}

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.search('/login') !== -1) return next(req)

  const srvAuth = inject(AuthService)
  const token = srvAuth.recoverToken() as AuthData

  if (!!!token?.token || (!!token?.token && isTokenExpired(token.token))) {
    srvAuth.logout()

    const router = inject(Router)
    router.navigate(['/login'], { queryParams: { session: 'expired' } })
    return next(req)
  }

  return next(
    req.clone({
      headers: req.headers.set(
        'Authorization',
        `Bearer ${!!token ? token.token : ''}`,
      ),
    }),
  )
}
