export const TABLE_FILE_NAMES = {
  time_records: 'TalenTrack - Registro de horario',
  expense: 'TalenTrack - Egresos',
  income: 'TalenTrack - Ingresos',
  companies: 'TalenTrack - Empresas',
  companies_archived: 'TalenTrack - Empresas (archivadas)',
  personnel_management: 'TalenTrack - Gestión de personal',
  personnel_management_archived:
    'TalenTrack - Gestión de personal (archivados)',
  users_overall: 'TalenTrack - Reporte de pagos',
}
