import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core'
import { initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getStorage, provideStorage } from '@angular/fire/storage'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideRouter, withHashLocation } from '@angular/router'
import { env } from '@env/dev.env'
import { jwtInterceptor } from '@utils/auth.interceptor'
import { routes } from './app.routes'

export const appConfig: ApplicationConfig = {
  providers: [
    provideFirebaseApp(() => initializeApp(env.firebase)),
    provideStorage(() => getStorage()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors([jwtInterceptor])),
    provideRouter(routes, withHashLocation()),
    provideAnimations(),
  ],
}
