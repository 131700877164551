import { env } from '@env/dev.env'

/**
 * GET obtener-todos/{tipo} (0 ingreso / 1 egreso)
 * POST crear-ingreso/{empresa_id}/{cuenta_id}
 * POST crear-egreso/{empresa_id}/{cuenta_id}
 * POST crear-ingreso-recurrente/{contrato_id}
 * POST crear-egreso-recurrente/{contrato_id}
 * DELETE eliminar/{registro_id}
 * PATCH editar-estado-egreso/{egreso_id}
 */
export const ApiAccounting = {
  obtenerIngresoEgresoTodos: `${env.api_url}contabilidad/obtener-todos`,
  crearIngreso: `${env.api_url}contabilidad/crear-ingreso`,
  crearEgreso: `${env.api_url}contabilidad/crear-egreso`,
  crearIngresoRecurrente: `${env.api_url}contabilidad/crear-ingreso-recurrente`,
  crearEgresoRecurrente: `${env.api_url}contabilidad/crear-egreso-recurrente`,
  eliminarRegistroPorID: `${env.api_url}contabilidad/eliminar`,
  resolverEgreso: `${env.api_url}contabilidad/editar-estado-egreso`,
}
