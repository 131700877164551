import {
  CurrencyPipe,
  DatePipe,
  Location,
  TitleCasePipe,
} from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { ApiAccounting } from '@api/api.accounting'
import { ApiContract } from '@api/api.contract'
import {
  Expense,
  Income,
  NewRecurringExpense,
  NewRecurringIncome,
} from '@interfaces/accounting.interface'
import { EmployeeCompany } from '@interfaces/contract.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { AccountingService } from '@services/accounting.service'
import { ApiService } from '@services/api.service'
import { PersonnelManagementService } from '@services/personnel-management.service'
import { ToastService } from '@services/toast.service'
import { ConfirmationService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputTextModule } from 'primeng/inputtext'
import { SidebarModule } from 'primeng/sidebar'
import { TableModule } from 'primeng/table'
import { TooltipModule } from 'primeng/tooltip'

@Component({
  selector: 'app-employee-contract',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    CalendarModule,
    FloatLabelModule,
    IconFieldModule,
    InputTextModule,
    InputNumberModule,
    TableModule,
    TooltipModule,
    ConfirmDialogModule,
    SidebarModule,
    ButtonModule,
    TitleCasePipe,
    DatePipe,
    CurrencyPipe,
  ],
  providers: [ConfirmationService],
  templateUrl: './employee-contract.component.html',
  styleUrl: './employee-contract.component.scss',
  host: {
    class: 'relative flexible shadow-sm rounded-[10px] p-5',
  },
})
export class EmployeeContractComponent implements OnInit {
  employee: Person | undefined
  company: EmployeeCompany | undefined
  columns = [
    'detalle',
    'monto',
    'fecha inicial',
    'fecha final',
    'acciones',
  ].map((column) => column.toLowerCase())
  income: Income[] = []
  expense: Expense[] = []
  new_recurring_item: NewRecurringIncome | NewRecurringExpense = {
    contract_id: -1,
    concepto: '',
    monto: 0,
    fecha_inicio: '',
    fecha_fin: '',
  }

  flagIsIncome = true
  sidebarVisible = false
  today = Intl.DateTimeFormat('es-EC', {
    month: 'long',
    year: 'numeric',
  }).format(new Date())
  iniDate = new Date()
  endDate: Date | undefined

  constructor(
    private router: Router,
    private location: Location,
    private srvAPI: ApiService,
    private srvPersonnelManagement: PersonnelManagementService,
    private srvAccounting: AccountingService,
    private confirmationService: ConfirmationService,
    private srvToast: ToastService,
  ) {
    // set ini variables
    this.srvPersonnelManagement.employee
      .asObservable()
      .subscribe((employee: Person | undefined) => {
        this.employee = employee
      })
    this.srvPersonnelManagement.company
      .asObservable()
      .subscribe((company: EmployeeCompany | undefined) => {
        this.company = company
      })

    this.srvAccounting.income$.subscribe((income: Income[]) => {
      // 0 recurrentes - 1 fijos
      this.income = income.filter(
        (item) =>
          item.forma === 0 && item.contrato._id === this.company?.contrato._id,
      )
    })
    this.srvAccounting.expense$.subscribe((expense: Expense[]) => {
      // 0 recurrentes - 1 fijos
      this.expense = expense.filter(
        (item) =>
          item.forma === 0 && item.contrato._id === this.company?.contrato._id,
      )
    })
  }

  ngOnInit() {
    this.initData()
  }

  initData() {
    this.srvAccounting.loadData()
    this.new_recurring_item = {
      contract_id: -1,
      concepto: '',
      monto: 0,
      fecha_inicio: '',
      fecha_fin: '',
    }
  }

  goToReportPerUser() {
    this.router.navigateByUrl(
      `/dashboard/report/per-user/${this.employee?.cuenta._id}/${this.company?.nombre}`,
    )
  }

  deleteEmployeeContract(event: Event, contrato_id: number | undefined) {
    if (!!!contrato_id) return

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Esta acción no puede deshacerse',
      header: 'Remover contrato del empleado',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Remover',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .delete(`${ApiContract.removerContrato}/${contrato_id}`)
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se eliminó el contrato',
              })
              this.srvPersonnelManagement.loadData()
              this.srvPersonnelManagement.company.next(undefined)
              this.location.back()
            },
            error: (error) => {
              this.srvToast.error({
                title: `Error ${error.status}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }

  deleteRecurringItem(
    event: Event,
    item: Income | Expense,
    flagIsIncome: boolean = true,
  ) {
    this.flagIsIncome = flagIsIncome

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        '¿Esta seguro que desea eliminar el registro? <br/> Esta acción no puede deshacerse',
      header: `Eliminar ${flagIsIncome ? 'ingreso' : 'egreso'}`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Eliminar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .delete(`${ApiAccounting.eliminarRegistroPorID}/${item._id}`)
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se eliminó el registro',
              })
              this.srvAccounting.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `${error.error.statusCode} ${error.error.error}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }

  openCreateRecurringItem(contract_id: number, flagIsIncome: boolean = true) {
    if (contract_id === -1) return

    this.flagIsIncome = flagIsIncome
    this.new_recurring_item.contract_id = contract_id
    this.iniDate = new Date()
    this.iniDate.setDate(1)
    this.sidebarVisible = true
  }

  createRecurringItem() {
    if (this.new_recurring_item.contract_id === -1) return

    this.new_recurring_item.fecha_inicio = this.iniDate
      .toISOString()
      .split('T')[0]
    if (!!this.endDate) {
      this.endDate.setMonth(this.endDate.getMonth() + 1)
      this.endDate.setDate(0)
      this.new_recurring_item.fecha_fin = this.endDate
        .toISOString()
        .split('T')[0]
    }

    const endpoint = this.flagIsIncome
      ? ApiAccounting.crearIngresoRecurrente
      : ApiAccounting.crearEgresoRecurrente
    this.srvAPI
      .post(
        `${endpoint}/${this.new_recurring_item.contract_id}`,
        this.new_recurring_item,
      )
      .subscribe({
        next: (response: any) => {
          this.srvToast.success({
            title: response.status,
            message: `Se creó el ${this.flagIsIncome ? 'ingreso' : 'egreso'}`,
          })
          this.initData()
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }
}
