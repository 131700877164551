import { Injectable, OnInit } from '@angular/core'
import { ApiCompanies } from '@api/api.companies'
import { BehaviorSubject } from 'rxjs'
import { Company } from 'src/app/interfaces/companies.interface'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class CompaniesService implements OnInit {
  archived: Company[] = []
  active: Company[] = []

  private companies = new BehaviorSubject<{
    archived: Company[]
    active: Company[]
  }>({ archived: [], active: [] })

  get companies$() {
    return this.companies.asObservable()
  }

  constructor(private srvAPI: ApiService) {}

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.srvAPI
      .get<{ data: Company[] }>(ApiCompanies.obtenerTodas)
      .subscribe((response: { data: Company[] }) => {
        const companies = response.data
        this.archived = companies.filter((item: any) => item['archivado'] == 1)
        this.active = companies.filter((item: any) => item['archivado'] == 0)
        this.companies.next({
          archived: this.archived,
          active: this.active,
        })
      })
  }
}
