<section class="flex flex-col flex-1">
  <div
    class="flex flex-row-reverse items-center justify-between w-full px-1 py-3 mb-5 lg:px-5 sm:justify-center min-[500px]:flex-row"
  >
    <div class="min-[1200px]:w-[50%] flex items-center">
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Volver"
        icon="pi pi-arrow-left"
        severity="success"
        [text]="true"
        (onClick)="goBack()"
      />
    </div>

    <div
      class="w-full min-[1200px]:w-[50%] mx-2 flex justify-center lg:justify-end items-center"
    >
      <p-iconField class="w-full lg:w-[70%]" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          class="w-full border-none rounded-full bg-grey-color"
          pInputText
          #pSearchInput
          type="text"
          placeholder="Identificación o nombre"
          (input)="pTable.filterGlobal(pSearchInput.value, 'contains')"
        />
      </p-iconField>
    </div>
  </div>

  <div class="max-w-[100vw] p-5 pt-0 mb-1 flex flex-1">
    <div class="w-[30%] shadow-md rounded-[10px] p-5 flex flex-col">
      <div
        class="flex flex-col items-center justify-center w-full py-5 mb-5 bg-aqua-color rounded-[10px]"
      >
        <img
          [src]="company?.url_logo"
          alt=""
          class="object-contain aspect-square w-[150px] h-[150px]"
          (click)="fileInput.click()"
        />
        <input
          #fileInput
          type="file"
          (change)="uploadFile($event)"
          style="display: none"
          accept="image/*"
        />
        <p class="mt-5 text-2xl">{{ company?.nombre }}</p>
      </div>
      <div class="flex flex-col flex-1 w-full py-5">
        <p class="flex justify-between text-lg">
          <span>Tipo:</span>
          <span>{{ company?.tipo_empresa?.nombre }}</span>
        </p>
        <p class="flex justify-between mt-5 text-lg">
          <span><span class="mx-2 pi pi-user"></span> Supervisor:</span>
          <span>{{ !!company?.supervisor ? company?.supervisor : 'N/A' }}</span>
        </p>
      </div>
      <div class="flex items-center justify-center w-full pb-10">
        <p-button
          styleClass="scale-125"
          label="Editar empresa"
          icon="pi pi-pencil"
          severity="danger"
          [text]="true"
          (onClick)="openEditCompany()"
        />
      </div>
    </div>
    <div class="max-w-[100vw] p-5 mb-1 overflow-y-scroll flex flex-col flex-1">
      <div class="flex justify-between w-full pb-5">
        <h3 class="text-2xl">Departamentos</h3>

        <p-button
          styleClass="text-nowrap py-2"
          type="button"
          label="Crear departamento"
          icon="pi pi-plus"
          severity="danger"
          [rounded]="true"
          (onClick)="openCreateDepartment()"
        />
      </div>

      <p-table
        #pTable
        class="max-h-[50vh] pb-3"
        styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
        tableStyleClass="min-w-[10px]"
        selectionMode="single"
        [scrollable]="true"
        [columns]="columns"
        [value]="pTableData"
        [globalFilterFields]="['nombre']"
        [paginator]="true"
        [alwaysShowPaginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Se muestran {last} de {totalRecords}"
      >
        <ng-template pTemplate="header">
          <tr>
            @for (column of columns; track $index) { @if ($index !==
            columns.length - 1) {
            <th [pSortableColumn]="column.field" class="w-[25%]">
              {{ column.header | titlecase }}
              <p-sortIcon [field]="column.field" />
            </th>
            } @else {
            <th class="auto">
              {{ column.header | titlecase }}
            </th>
            } }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-department>
          <tr>
            <td>{{ department.nombre }}</td>
            <td>{{ department.plazas }}</td>
            <td class="flex items-center gap-x-1 custom-table-actions">
              <p-button
                styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
                icon="pi pi-inbox"
                (onClick)="archiveDepartmentInfo($event, department)"
              />
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</section>

<!-- MARK: MODALS -->
<div class="flex items-center justify-center">
  <p-confirmDialog />
</div>

<p-sidebar
  styleClass="w-[350px] flex flex-col flex-1"
  [(visible)]="sidebarCompany"
  position="right"
>
  <ng-template pTemplate="header">
    <h3>Editar empresa</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form
      class="flex flex-col justify-around flex-1 px-5 pb-10"
      (submit)="editCompany()"
    >
      <div class="flex flex-col items-center flex-1 w-full gap-y-10">
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_company['nombre']"
            id="name"
            type="text"
            name="name"
          />
          <label for="name">Nombre de la empresa</label>
        </p-floatLabel>
        <p-dropdown
          class="w-full"
          styleClass="w-full"
          [options]="company_types"
          [(ngModel)]="new_company.tipo_empresa"
          optionLabel="nombre"
          id="company_types"
          name="company_types"
          placeholder="Tipo de empresa"
          (onChange)="new_company.tipo_empresa = $event.value"
        />
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_company['supervisor']"
            id="supervisor"
            type="text"
            name="supervisor"
          />
          <label for="supervisor">Supervisor</label>
        </p-floatLabel>
      </div>

      <div class="flex items-center justify-center w-full h-[7vh]">
        <p-button
          class="w-[70%]"
          styleClass="w-full"
          label="Guardar cambios"
          severity="danger"
          [rounded]="true"
          type="submit"
        />
      </div>
    </form>
  </ng-template>
</p-sidebar>

<p-sidebar
  styleClass="w-[350px] flex flex-col flex-1"
  [(visible)]="sidebarDepartment"
  position="right"
>
  <ng-template pTemplate="header">
    <h3>Nuevo departamento</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form
      class="flex flex-col justify-around flex-1 px-5 pb-10"
      (submit)="createDepartment()"
    >
      <div class="flex flex-col items-center flex-1 w-full pt-5 gap-y-10">
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            id="company"
            type="text"
            name="company"
            disabled
            [value]="company?.nombre"
          />
          <label for="company">Empresa</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_department['nombre']"
            id="name"
            type="text"
            name="name"
          />
          <label for="name">Nombre del departamento</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            [(ngModel)]="new_department['plazas']"
            [showButtons]="true"
            buttonLayout="horizontal"
            inputId="minmax"
            mode="decimal"
            [min]="0"
            [max]="100"
            spinnerMode="horizontal"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            name="openings"
            id="openings"
          />
          <label for="openings">Número de plazas</label>
        </p-floatLabel>
      </div>

      <div class="flex items-center justify-center w-full h-[7vh]">
        <p-button
          class="w-[70%]"
          styleClass="w-full"
          label="Registrar empresa"
          severity="danger"
          [rounded]="true"
          type="submit"
        />
      </div>
    </form>
  </ng-template>
</p-sidebar>
