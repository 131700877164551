import { Location, TitleCasePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ApiPersonnelManagement } from '@api/api.personnel-management'
import { pTableColumn, pTableEmployee } from '@interfaces/p-table.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { PersonnelManagementService } from '@services/personnel-management.service'
import { ToastService } from '@services/toast.service'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ChipModule } from 'primeng/chip'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { Table, TableModule } from 'primeng/table'
import { ApiService } from 'src/app/services/api.service'
import { TABLE_FILE_NAMES } from 'src/app/utils/p-table-names'

@Component({
  selector: 'app-personnel-management-archived',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
    ButtonModule,
    TitleCasePipe,
    ChipModule,
  ],
  templateUrl: './personnel-management-archived.component.html',
  styleUrl: './personnel-management-archived.component.scss',
  host: {
    class: 'flexible',
  },
})
export class PersonnelManagementArchivedComponent implements OnInit {
  // MARK: class members
  // table
  table_name = TABLE_FILE_NAMES['personnel_management_archived']
  columns: pTableColumn[] = [
    { field: 'usuario', header: 'identificación' },
    { field: 'nombre', header: 'usuario' },
    { field: 'correo', header: 'correo' },
    { field: 'telefono', header: 'teléfono' },
    { field: 'especializaciones', header: 'especialización' },
    { field: 'acciones', header: 'acciones' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableEmployee[] = []
  employees: Person[] = []

  constructor(
    private location: Location,
    private srvAPI: ApiService,
    private srvPersonnelManagement: PersonnelManagementService,
    private srvToast: ToastService,
  ) {
    this.srvPersonnelManagement.employees$.subscribe((employees: any) => {
      this.employees = employees['archived'] as Person[]
      this.loadTable()
    })
  }

  ngOnInit() {
    this.srvPersonnelManagement.loadData()
  }

  loadTable() {
    this.pTableData = this.employees.map((item) => {
      return {
        _id: item.cuenta._id,
        usuario: item.cuenta.usuario,
        nombre: `${item.nombre} ${item.apellido}`,
        correo: item.correo,
        telefono: item.telefono,
        especializaciones: item.personaEspecialidades.map(
          (esp) => esp.especialidad.nombre,
        ),
      } as pTableEmployee
    })
  }

  exportToCSV(pTable: Table) {
    const last = this.columns.pop()
    pTable.exportCSV()
    if (last) this.columns.push(last)
  }

  goBack() {
    this.location.back()
  }

  unfileEmployeeInfo(employee: Person) {
    this.srvAPI
      .patch(`${ApiPersonnelManagement.archivar}/${employee._id}/0`, {})
      .subscribe({
        next: (response: any) => {
          this.srvToast.info({
            title: response.status,
            message: 'El empleado fue habilitado',
          })
          this.srvPersonnelManagement.loadData()
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }
}
