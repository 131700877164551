import { env } from '@env/dev.env'

/**
 * POST crear
 * GET cuenta/buscar-empresas-contrato/{cuenta_id}
 * PATCH cambiar_estado/{contrato_id}
 * DELETE remover-contrato/{contrato_id}
 * GET reporte-pago/{empresa_id}/{cuenta_id}/{fecha}
 * GET obtener-todos
 * POST libro_pagos/registrar_pago/{contrato_id}
 */
export const ApiContract = {
  crear: `${env.api_url}contrato/crear`,
  obtenerEmpresasContratoDeEmpleado: `${env.api_url}cuenta/buscar-empresas-contrato-departamento`,
  cambiarEstado: `${env.api_url}contrato/cambiar_estado`,
  removerContrato: `${env.api_url}contrato/remover-contrato`,
  obtenerReporteDePago: `${env.api_url}contrato/reporte-pago`,
  obtenerTodos: `${env.api_url}contrato/obtener-todos`,
  registrarPago: `${env.api_url}libro_pagos/registrar_pago`,
}
