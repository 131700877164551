import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApiContract } from '@api/api.contract'
import { EmployeeCompany } from '@interfaces/contract.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { ApiService } from '@services/api.service'
import { PersonnelManagementService } from '@services/personnel-management.service'
import { InfoPanelComponent } from './components/info-panel/info-panel.component'
import { UserCompaniesComponent } from './components/user-companies/user-companies.component'
import { UserListComponent } from './components/user-list/user-list.component'

@Component({
  selector: 'app-per-user',
  standalone: true,
  imports: [UserListComponent, UserCompaniesComponent, InfoPanelComponent],
  templateUrl: './per-user.component.html',
  styleUrl: './per-user.component.scss',
  host: {
    class: 'flexible',
  },
})
export class PerUserComponent implements OnInit {
  employees: Person[] = []
  employee: Person | undefined
  employee_companies: EmployeeCompany[] = []
  hasCompanies = true
  company: EmployeeCompany | undefined

  constructor(
    private route: ActivatedRoute,
    private srvAPI: ApiService,
    private srvPersonnelManagement: PersonnelManagementService,
  ) {
    this.srvPersonnelManagement.employees$.subscribe((employees: any) => {
      this.employees = employees['active'] as Person[]

      // route :employee
      const employeeID = +this.route.snapshot.params['employee']
      if (!!!employeeID) {
        this.srvPersonnelManagement.employee.next(undefined)
        return
      }

      this.employee = this.employees.find(
        (employee) => employee.cuenta._id === employeeID,
      )

      if (!!this.employee) {
        this.srvPersonnelManagement.employee.next(this.employee)
        this.srvAPI
          .get(
            `${ApiContract.obtenerEmpresasContratoDeEmpleado}/${this.employee?.cuenta._id}`,
          )
          .subscribe((response: any) => {
            this.employee_companies = response['data'][
              'empresas'
            ] as EmployeeCompany[]
            this.hasCompanies = this.employee_companies.length > 0

            // route :employee:company
            this.route.firstChild?.params.subscribe((childParams) => {
              const company = childParams['company']
              this.company = this.employee_companies.find(
                (employee_company) => employee_company.nombre === company,
              )
            })
          })
      }
    })
  }

  ngOnInit(): void {
    this.srvPersonnelManagement.loadData()
  }
}
