<div class="flex justify-between flex-1 gap-10 p-5">
  <section class="flexible">
    <app-user-list [employees]="employees"></app-user-list>
  </section>
  <section class="flexible">
    @if (!!employee) {
    <app-user-companies
      [employee]="employee"
      [employee_companies]="employee_companies"
      [hasCompanies]="hasCompanies"
    ></app-user-companies>
    }
  </section>
  <section class="flex flex-col flex-[2]">
    @if (!!company) {
    <app-info-panel [employee]="employee" [company]="company"></app-info-panel>
    }
  </section>
</div>
