import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router'
import { Person } from '@interfaces/personnel-management.interface'
import { AvatarModule } from 'primeng/avatar'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { ListboxModule } from 'primeng/listbox'

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    FormsModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    AvatarModule,
    ListboxModule,
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
  host: {
    class: 'flexible',
  },
})
export class UserListComponent implements OnChanges, AfterViewChecked {
  @Input() employees: Person[] = []
  employeesFiltered: Person[] = []
  employeeInput = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elRef: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const employees = changes['employees'].currentValue as Person[]
    if (employees) {
      this.employees = employees
      this.employeesFiltered = employees
    }
  }

  ngAfterViewChecked(): void {
    const active = this.elRef.nativeElement.querySelector('.active')
    if (active) active.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  redirect(employeeID: number) {
    this.router.navigate(['/dashboard/report/per-user']).then(() => {
      this.router.navigate([employeeID], { relativeTo: this.route.parent })
    })
  }

  onEmployeeInput(event: Event) {
    const input = (event.target as HTMLInputElement).value
    if (input === '') {
      this.employeesFiltered = this.employees
      return
    }

    this.employeesFiltered = this.employees.filter((employee) =>
      `${employee.nombre} ${employee.apellido}`
        .toLowerCase()
        .includes(input.trim().toLowerCase()),
    )
  }
}
