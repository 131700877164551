<div class="flexible ring-1 ring-[gainsboro] rounded-2xl">
  <p-floatLabel class="w-full p-3 mt-5 mb-2">
    <input
      [(ngModel)]="employeeInput"
      class="w-full"
      pInputText
      id="employee"
      type="text"
      name="employee"
      (input)="onEmployeeInput($event)"
    />
    <label for="employee">Empleado</label>
  </p-floatLabel>
  <div class="relative w-full mb-4 overflow-y-scroll flexible">
    <div class="absolute w-full p-3 flexible gap-y-5">
      @for (employee of employeesFiltered; track $index) {
      <a
        [id]="employee.cuenta.usuario"
        role="button"
        class="flex w-full px-3 py-2 rounded-2xl hover:bg-grey-hover"
        [routerLink]="['/dashboard/report/per-user/', employee.cuenta._id]"
        routerLinkActive="bg-aqua-color active"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="redirect(employee.cuenta._id)"
      >
        <p-avatar
          [image]="employee.url_image || undefined"
          [label]="!!employee.url_image ? undefined : employee.nombre[0]"
          styleClass="mr-2 bg-[gainsboro] object-contain"
          shape="circle"
          size="large"
        />
        <p class="text-left truncate">
          <span title="{{ employee.nombre + ' ' + employee.apellido }}">{{
            employee.nombre + ' ' + employee.apellido
          }}</span>
          <br />
          <span class="text-sm capitalize">{{ employee.cuenta.usuario }}</span>
        </p>
      </a>
      }
    </div>
  </div>
</div>
