<div class="flexible ring-1 ring-[gainsboro] rounded-2xl">
  <div class="relative w-full my-4 overflow-y-scroll flexible">
    <div class="absolute w-full p-3 flexible gap-y-5">
      @if (!hasCompanies) {
      <p>El usuario no tiene contratos</p>
      } @else if (!!employee) { @for (company of employee_companies; track
      $index) {
      <a
        role="button"
        class="flex items-center w-full px-3 py-2 rounded-2xl hover:bg-grey-hover"
        [routerLink]="[
          '/dashboard/report/per-user/',
          employee.cuenta._id,
          company.nombre
        ]"
        routerLinkActive="bg-aqua-color"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="redirect(company.nombre)"
      >
        <p-avatar
          [image]="company.url_logo || undefined"
          [label]="!!company.url_logo ? undefined : company.nombre[0]"
          styleClass="mr-2 bg-[gainsboro] object-contain"
          shape="circle"
          size="large"
        />
        <p class="text-left">
          <span>{{ company.nombre }}</span>
        </p>
      </a>

      } }
    </div>
  </div>
</div>
