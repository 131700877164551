import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { PanelMenuModule } from 'primeng/panelmenu'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [PanelMenuModule, ButtonModule],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
})
export class NavComponent implements OnChanges {
  @Input() items: MenuItem[] = []

  constructor(private router: Router, private srvAuth: AuthService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const items = changes['items'].currentValue
    if (!!items) this.items = items
  }

  logout() {
    this.srvAuth.logout()
    this.router.navigateByUrl('/login')
  }
}
