import { Location, TitleCasePipe } from '@angular/common'
import { AfterViewChecked, Component, OnInit } from '@angular/core'
import {
  Storage,
  getDownloadURL,
  ref,
  uploadBytes,
} from '@angular/fire/storage'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiCompanies, ApiDepartments } from '@api/api.companies'
import { pTableColumn, pTableDepartment } from '@interfaces/p-table.interface'
import { ToastService } from '@services/toast.service'
import { ConfirmationService } from 'primeng/api'
import { CalendarModule } from 'primeng/calendar'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputTextModule } from 'primeng/inputtext'
import { SidebarModule } from 'primeng/sidebar'
import { TableModule } from 'primeng/table'
import {
  Company,
  CompanyType,
  Department,
  NewCompany,
  NewDepartment,
} from 'src/app/interfaces/companies.interface'
import { ApiService } from 'src/app/services/api.service'
import { CompaniesService } from 'src/app/services/companies.service'

@Component({
  selector: 'app-company-info',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    IconFieldModule,
    FloatLabelModule,
    InputIconModule,
    InputTextModule,
    InputNumberModule,
    TableModule,
    TitleCasePipe,
    ConfirmDialogModule,
    SidebarModule,
    DropdownModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './company-info.component.html',
  styleUrl: './company-info.component.scss',
  host: {
    class: 'flexible',
  },
})
export class CompanyInfoComponent implements OnInit, AfterViewChecked {
  // MARK: class members
  // table
  columns: pTableColumn[] = [
    { field: 'nombre', header: 'departamento' },
    { field: 'plazas', header: 'plazas' },
    { field: 'acciones', header: 'acciones' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableDepartment[] = []

  downloadURL: string | null = null
  files: string[] = []
  companies: Company[] = []
  company: Company | undefined
  new_company: NewCompany = {
    nombre: '',
    supervisor: '',
    tipo_empresa: {
      _id: -1,
      nombre: '',
    },
  }
  company_types: CompanyType[] = []
  departments: Department[] = []
  new_department: NewDepartment = {
    _id: 0,
    nombre: '',
    plazas: 0,
    archivado: 0,
    id_empresa: 0,
  }

  sidebarCompany = false
  sidebarDepartment = false

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private srvAPI: ApiService,
    private srvCompanies: CompaniesService,
    private confirmationService: ConfirmationService,
    private storage: Storage,
    private srvToast: ToastService,
  ) {
    this.srvCompanies.companies$.subscribe((companies: any) => {
      this.companies = companies['active'] as Company[]

      const companyName = this.route.snapshot.params['company']
      this.company = this.companies.find(
        (company: Company) =>
          company.nombre.toLowerCase() === companyName.toLowerCase(),
      )
      this.departments =
        this.company?.departamentos.filter((dep) => dep.archivado == 0) || []
      this.loadTable()
    })
  }

  ngOnInit() {
    this.srvAPI.get(ApiCompanies.obtenerTipos).subscribe((response: any) => {
      this.company_types = response['data'] as CompanyType[]
    })
    this.srvCompanies.loadData()
  }

  ngAfterViewChecked(): void {
    this.addButtonToTableFooter()
  }

  addButtonToTableFooter() {
    if (!!document.getElementById('paginator-container')) return

    const footer = document.querySelector('p-paginator div.p-paginator')
    if (footer) {
      const nodo = document.createElement('div')
      nodo.setAttribute('id', 'paginator-container')
      nodo.setAttribute(
        'class',
        'flex flex-1 items-center justify-center absolute inset-0',
      )
      const sibling = footer.querySelector('button[aria-label="First Page"]')
      if (sibling) footer.insertBefore(nodo, sibling)

      const div = document.getElementById('paginator-container')
      if (div) {
        const el = `
          <button
            id="paginator-button"
            class="flex items-center justify-around text-nowrap p-2 w-[120px] bg-transparent font-bold text-[1rem] rounded-[2rem] text-[#64748b] border-[1px] border-[#64748b]"
            type="button"
          >Ver archivados</button>
          `
        div.insertAdjacentHTML('afterbegin', el)
        const button = document.getElementById('paginator-button')
        if (button) {
          const icon = '<i class="pi pi-inbox text-lg"></i>'
          button.insertAdjacentHTML('afterbegin', icon)
          button.addEventListener('click', () => {
            this.goToArchived()
          })
        }
      }
    }
  }

  loadTable() {
    this.pTableData = this.departments.map((item) => {
      return {
        _id: item._id,
        nombre: item.nombre,
        plazas: item.plazas,
      } as pTableDepartment
    })
  }

  async uploadFile(event: any) {
    const file = event.target.files[0]
    if (file) {
      const storageRef = ref(
        this.storage,
        `empresa/${this.company?._id}/${file.name}`,
      )
      const snapshot = await uploadBytes(storageRef, file)
      this.downloadURL = await getDownloadURL(snapshot.ref)
      const urlObj = new URL(this.downloadURL)
      urlObj.searchParams.delete('token')
      try {
        this.srvAPI
          .patch(`${ApiCompanies.actualizarLogo}/${this.company?._id}`, {
            url_logo: urlObj,
          })
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'El logo fue actualizado',
              })
              this.srvCompanies.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `${error.error.statusCode} ${error.error.error}`,
                message: error.error.message,
              })
            },
          })
      } catch (error) {
        console.error('Invalid URL:', error)
      }
    }
  }

  goBack() {
    this.location.back()
  }

  goToArchived() {
    if (!!!this.company) return

    this.router.navigateByUrl(
      `/dashboard/companies/${this.company.nombre}/departments/archived`,
    )
  }

  archiveDepartmentInfo(event: Event, deparment: Department) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Esta acción desactivará el departamento',
      header: 'Archivar el Departamento',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Archivar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .patch(`${ApiDepartments.archivar}/${deparment._id}/1`, {})
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se archivó el departamento',
              })
              this.srvCompanies.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `${error.error.statusCode} ${error.error.error}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }

  openEditCompany() {
    if (!!!this.company) return

    this.new_company = {
      nombre: this.company.nombre,
      supervisor: this.company.supervisor || '',
      tipo_empresa: {
        _id: this.company.tipo_empresa._id,
        nombre:
          this.company_types.find(
            (tipo) => tipo._id === this.company?.tipo_empresa._id,
          )?.nombre || '',
      },
    }

    this.sidebarCompany = true
  }

  editCompany() {
    if (!!!this.company) return

    this.srvAPI
      .patch(`${ApiCompanies.actualizar}/${this.company?._id}`, {
        id_tipo_empresa: this.new_company.tipo_empresa._id,
        ...this.new_company,
      })
      .subscribe({
        next: (response: any) => {
          this.srvToast.success({
            title: response.status,
            message: 'Se actualizó la empresa',
          })
          this.srvCompanies.loadData()
          this.sidebarCompany = false
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }

  openCreateDepartment() {
    if (!!!this.company) return

    this.sidebarDepartment = true
    this.new_department.id_empresa = this.company._id
  }

  createDepartment() {
    if (!!!this.company) return

    this.srvAPI.post(ApiDepartments.crear, this.new_department).subscribe({
      next: (response: any) => {
        this.srvToast.success({
          title: response.status,
          message: 'Se creó el departamento',
        })
        this.srvCompanies.loadData()
        this.sidebarDepartment = false
      },
      error: (error) => {
        this.srvToast.error({
          title: `${error.error.statusCode} ${error.error.error}`,
          message: error.error.message,
        })
      },
    })
  }
}
