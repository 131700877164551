import { Location, TitleCasePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ApiDepartments } from '@api/api.companies'
import { pTableColumn, pTableDepartment } from '@interfaces/p-table.interface'
import { ToastService } from '@services/toast.service'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { TableModule } from 'primeng/table'
import { Company, Department } from 'src/app/interfaces/companies.interface'
import { ApiService } from 'src/app/services/api.service'
import { CompaniesService } from 'src/app/services/companies.service'

@Component({
  selector: 'app-departments-archived',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
    ButtonModule,
    TitleCasePipe,
  ],
  templateUrl: './departments-archived.component.html',
  styleUrl: './departments-archived.component.scss',
  host: {
    class: 'flexible',
  },
})
export class DepartmentsArchivedComponent implements OnInit {
  // MARK: class members
  // table
  columns: pTableColumn[] = [
    { field: 'nombre', header: 'departamento' },
    { field: 'plazas', header: 'plazas' },
    { field: 'acciones', header: 'acciones' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableDepartment[] = []

  companies: Company[] = []
  company: Company | undefined
  departments: Department[] = []

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private srvAPI: ApiService,
    private srvCompanies: CompaniesService,
    private srvToast: ToastService,
  ) {
    this.srvCompanies.companies$.subscribe((companies: any) => {
      this.companies = companies['active'] as Company[]

      const companyName = this.route.snapshot.params['company']
      this.company = this.companies.find(
        (company: Company) =>
          company.nombre.toLowerCase() === companyName.toLowerCase(),
      )
      this.departments =
        this.company?.departamentos.filter((dep) => dep.archivado == 1) || []
      this.loadTable()
    })
  }

  ngOnInit() {
    this.srvCompanies.loadData()
  }

  loadTable() {
    this.pTableData = this.departments.map((item) => {
      return {
        _id: item._id,
        nombre: item.nombre,
        plazas: item.plazas,
      } as pTableDepartment
    })
  }

  goBack() {
    this.location.back()
  }

  unfileDepartmentInfo(department: Department) {
    this.srvAPI
      .patch(`${ApiDepartments.archivar}/${department._id}/0`, {})
      .subscribe({
        next: (response: any) => {
          this.srvToast.info({
            title: response.status,
            message: 'El departamento fue habilitado',
          })
          this.srvCompanies.loadData()
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }
}
