import { Injectable } from '@angular/core';
import { ApiAccounting } from '@api/api.accounting';
import { Expense, Income } from '@interfaces/accounting.interface';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  private expense = new BehaviorSubject<Expense[]>([])
  private income = new BehaviorSubject<Income[]>([])

  get expense$() {
    return this.expense.asObservable()
  }
  get income$() {
    return this.income.asObservable()
  }


  constructor(private srvAPI: ApiService) { }

  loadData() {
    this.loadExpense()
    this.loadIncome()
  }

  loadExpense() {
    this.srvAPI.get(`${ApiAccounting.obtenerIngresoEgresoTodos}/1`).subscribe(
      (response: any) => {
        const expense = response['data'] as Expense[]
        this.expense.next(expense)
      }
    )
  }

  loadIncome() {
    this.srvAPI.get(`${ApiAccounting.obtenerIngresoEgresoTodos}/0`).subscribe(
      (response: any) => {
        const income = response['data'] as Income[]
        this.income.next(income)
      }
    )
  }


}
