import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { RouterOutlet } from '@angular/router'
import { ToastService } from '@services/toast.service'
import { MessageService, PrimeNGConfig } from 'primeng/api'
import { ToastModule, ToastPositionType } from 'primeng/toast'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule],
  providers: [MessageService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  positions: ToastPositionType[] = [
    'top-left',
    'top-center',
    'top-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
    'center',
  ]

  constructor(
    private srvTitle: Title,
    private primengConfig: PrimeNGConfig,
    private messageService: MessageService,
    private srvToast: ToastService,
  ) {}

  ngOnInit() {
    const lang = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      clear: 'Limpiar',
      dateFormat: 'dd-mm-yy',
      weekHeader: 'Sm',
    }
    this.srvTitle.setTitle('TalenTrack')
    this.primengConfig.setTranslation(lang)
    this.srvToast.setService(this.messageService)
  }
}
