import { Routes } from '@angular/router'
import { CompaniesArchivedComponent } from '@pages/companies-archived/companies-archived.component'
import { CompanyInfoComponent } from '@pages/company-info/company-info.component'
import { DepartmentsArchivedComponent } from '@pages/departments-archived/departments-archived.component'
import { EmployeeContractComponent } from '@pages/employee-info/components/employee-contract/employee-contract.component'
import { EmployeeInfoComponent } from '@pages/employee-info/employee-info.component'
import { PersonnelManagementArchivedComponent } from '@pages/personnel-management-archived/personnel-management-archived.component'
import { CompaniesComponent } from './pages/companies/companies.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { ExpenseComponent } from './pages/expense/expense.component'
import { IncomeComponent } from './pages/income/income.component'
import { PersonnelManagementComponent } from './pages/personnel-management/personnel-management.component'
import { PerUserComponent } from './pages/report/per-user/per-user.component'
import { UsersOverallComponent } from './pages/report/users-overall/users-overall.component'
import { TimeRecordsComponent } from './pages/time-records/time-records.component'
import { authGuard } from './utils/auth.guard'

export const routes: Routes = [
  { path: '', title: 'TalenTrack', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    canActivate: [authGuard],
    title: 'TalenTrack',
    path: 'dashboard',
    component: DashboardComponent,
    canActivateChild: [authGuard],
    children: [
      { path: 'time-records', component: TimeRecordsComponent },
      { path: 'income', component: IncomeComponent },
      { path: 'expense', component: ExpenseComponent },

      {
        path: 'report',
        children: [
          {
            path: 'per-user',
            children: [
              { path: '', component: PerUserComponent },
              {
                path: ':employee',
                component: PerUserComponent,
                children: [{ path: ':company', component: PerUserComponent }],
              },
            ],
          },
          { path: 'users-overall', component: UsersOverallComponent },
          { path: '', redirectTo: 'per-user', pathMatch: 'full' },
        ],
      },

      {
        path: 'personnel-management',
        children: [
          { path: '', component: PersonnelManagementComponent },
          { path: 'archived', component: PersonnelManagementArchivedComponent },
          {
            path: ':employee',
            component: EmployeeInfoComponent,
            children: [
              {
                path: ':company',
                component: EmployeeContractComponent,
                outlet: 'employee-contract',
              },
            ],
          },
        ],
      },

      {
        path: 'companies',
        children: [
          { path: '', component: CompaniesComponent },
          { path: 'archived', component: CompaniesArchivedComponent },
          {
            path: ':company',
            children: [
              { path: '', component: CompanyInfoComponent },
              {
                path: 'departments/archived',
                component: DepartmentsArchivedComponent,
              },
            ],
          },
        ],
      },

      { path: '', redirectTo: 'time-records', pathMatch: 'full' },
    ],
  },
  {
    path: 'login',
    title: 'TalenTrack | Bienvenido',
    loadComponent: () =>
      import('./pages/login/login.component').then(
        (comp) => comp.LoginComponent,
      ),
  },
  {
    path: '**',
    title: 'TalenTrack',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (comp) => comp.NotFoundComponent,
      ),
  },
]
