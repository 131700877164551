import { env } from "@env/dev.env";

/**
 * GET obtener-todas
 * GET tipo-empresa/obtener-todos
 * POST crear
 * PATCH archivar/{empresa_id}/{valor}
 * PATCH actualizar/{empresa_id}
 * PATCH actualizar-logo
*/
export const ApiCompanies = {
  obtenerTodas: `${env.api_url}empresa/obtener-todas`,
  obtenerTipos: `${env.api_url}tipo-empresa/obtener-todos`,
  crear: `${env.api_url}empresa/crear`,
  archivar: `${env.api_url}empresa/archivar`,
  actualizar: `${env.api_url}empresa/actualizar`,
  actualizarLogo: `${env.api_url}empresa/actualizar-logo`,
}

/**
 * PATCH archivar/{departamento_id}/{valor}
 * POST crear
 */
export const ApiDepartments = {
  archivar: `${env.api_url}departamento/archivar`,
  crear: `${env.api_url}departamento/crear`,
}
