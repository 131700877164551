import { Injectable, OnInit } from '@angular/core';
import { ApiPersonnelManagement } from '@api/api.personnel-management';
import { EmployeeCompany } from '@interfaces/contract.interface';
import { Person } from '@interfaces/personnel-management.interface';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PersonnelManagementService implements OnInit {

  archived: Person[] = []
  active: Person[] = []

  private employees = new BehaviorSubject<{
    archived: Person[],
    active: Person[]
  }>({ archived: [], active: [] })

  employee = new BehaviorSubject<Person | undefined>(undefined)
  company = new BehaviorSubject<EmployeeCompany | undefined>(undefined)


  get employees$() {
    return this.employees.asObservable()
  }


  constructor(private srvAPI: ApiService) { }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.srvAPI.get(ApiPersonnelManagement.obtenerTodos).subscribe(
      (response: any) => {
        const employees = response['data'] as Person[]
        this.archived = employees.filter((item: any) => item['cuenta']['archivado'] == 1)
        this.active = employees.filter((item: any) => item['cuenta']['archivado'] == 0)
        this.employees.next({
          archived: this.archived,
          active: this.active,
        })
      }
    )
  }


}
