import { Component } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DatePipe } from '@angular/common';
import { TimeRecord } from '@interfaces/time-records.interface';
interface LooseObject {
  [key: string]: any;
}
@Component({
  selector: 'modal-component',
  templateUrl: './modal.component.html',
  standalone: true,
  imports: [DialogModule, ButtonModule, DatePipe],
})
export class ModalComponent {
  visible: boolean = false;
  persona!: TimeRecord;

  showDialog(persona: TimeRecord) {
    this.persona = persona;
    this.visible = true;
  }

  closeDialog() {
    this.visible = false;
  }

  restarMinutos(tiempo: string, minutosARestar: number, opcioneS = {}) {
    const opciones = {
      modoReloj: false,  // Si es true, da la vuelta al reloj. Si es false, permite resultados negativos.
      formatoNegativo: true,  // Si es true, muestra el signo negativo para duraciones negativas.
      ...opcioneS
    };

    // Convertir el string de tiempo a segundos
    const [horas, minutos, segundos] = tiempo.split(':').map(Number);
    let totalSegundos = horas * 3600 + minutos * 60 + segundos;

    // Restar los minutos
    totalSegundos -= minutosARestar * 60;

    // Manejar casos según las opciones
    if (opciones.modoReloj && totalSegundos < 0) {
      totalSegundos += 24 * 60 * 60; // Agregar un día completo
    }

    // Convertir de vuelta a horas, minutos y segundos
    const signo = totalSegundos < 0 ? -1 : 1;
    totalSegundos = Math.abs(totalSegundos);
    const hours = Math.floor(totalSegundos / 3600);
    const mins = Math.floor((totalSegundos % 3600) / 60);
    const secs = totalSegundos % 60;

    // Formatear el resultado
    let resultado = `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    if (signo < 0 && !opciones.modoReloj && opciones.formatoNegativo) {
      resultado = '-' + resultado;
    }

    return resultado;
  }
}
