import { Component, OnInit } from '@angular/core'
import { Router, RouterOutlet } from '@angular/router'
import { AccountComponent } from '@pages/account/account.component'
import { MenuItem } from 'primeng/api'
import { AvatarModule } from 'primeng/avatar'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { ButtonModule } from 'primeng/button'
import { ChipModule } from 'primeng/chip'
import { PanelMenuModule } from 'primeng/panelmenu'
import { SidebarModule } from 'primeng/sidebar'
import { ToolbarModule } from 'primeng/toolbar'
import { AuthService } from 'src/app/services/auth.service'
import { NavComponent } from './components/nav/nav.component'

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    RouterOutlet,
    SidebarModule,
    PanelMenuModule,
    ButtonModule,
    NavComponent,
    ToolbarModule,
    BreadcrumbModule,
    ChipModule,
    AvatarModule,
    AccountComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  user: { name: string; role: string } = { name: '', role: '' }
  sidebarVisible = false
  routes: MenuItem[] = []
  home: MenuItem = {}
  visibilityModal = false

  constructor(private router: Router, private srvAuth: AuthService) {
    const token = this.srvAuth.recoverToken()
    if (token) {
      this.user.name = `${token['user']['persona']['nombre']} ${token['user']['persona']['apellido']}`
      this.user.role = token['roles'][0]['nombre']
    }
  }

  get activeRoute() {
    const menu: MenuItem[] = []
    const item = this.routes.find((route) => {
      if (!!route.items)
        return route.items.find((sub) => this.router.url.includes(sub['route']))

      return this.router.url.includes(route['route'])
    })

    if (item) {
      menu.push(item)
      if (!!item.items) {
        const subitem = item.items.find((sub) =>
          this.router.url.includes(sub['route']),
        )
        if (subitem) menu.push(subitem)
      }
    }
    return menu
  }

  ngOnInit() {
    this.home = { icon: 'pi pi-home', route: '/dashboard/time-records' }
    this.routes = [
      {
        label: 'Registro de horas',
        icon: 'pi pi-clock',
        route: '/dashboard/time-records',
      },
      {
        label: 'Otros egresos',
        icon: 'pi pi-angle-double-down',
        route: '/dashboard/expense',
      },
      {
        label: 'Otros ingresos',
        icon: 'pi pi-angle-double-up',
        route: '/dashboard/income',
      },
      {
        label: 'Reportes',
        icon: 'pi pi-folder-open',
        separator: false,
        expanded: true,
        items: [
          {
            label: 'Reporte usuarios',
            icon: 'pi pi-address-book',
            route: '/dashboard/report/per-user',
          },
          {
            label: 'Reporte general',
            icon: 'pi pi-book',
            route: '/dashboard/report/users-overall',
          },
        ],
      },
      {
        label: 'Gestión de personal',
        icon: 'pi pi-users',
        route: '/dashboard/personnel-management',
      },
      {
        label: 'Empresas',
        icon: 'pi pi-building',
        route: '/dashboard/companies',
      },
    ]
  }

  changeAccountModal(state: boolean) {
    this.visibilityModal = state
  }
}
