<section class="flex flex-col flex-1">
  <!-- id="bar-section" -->
  <div
    class="flex flex-row-reverse items-center justify-between w-full px-1 py-3 mb-5 lg:px-5 sm:justify-center min-[500px]:flex-row"
  >
    <div class="min-[1200px]:w-[40%] flex items-center">
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Volver"
        icon="pi pi-arrow-left"
        severity="success"
        [text]="true"
        (onClick)="goBack()"
      />
    </div>

    <div
      class="w-full min-[1200px]:w-[40%] mx-2 flex justify-center lg:justify-start items-center"
    >
      <p-iconField class="w-full lg:w-auto" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          class="rounded-full w-full lg:w-[200%] bg-grey-color border-none"
          pInputText
          #pSearchInput
          type="text"
          placeholder="Identificación o nombre"
          (input)="pTable.filterGlobal(pSearchInput.value, 'contains')"
        />
      </p-iconField>
    </div>

    <div
      class="min-[1200px]:w-[20%] hidden sm:flex sm:justify-center lg:justify-end place-self-end"
    >
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Descargar reporte"
        icon="pi pi-download"
        severity="danger"
        [rounded]="true"
        [outlined]="true"
        (onClick)="exportToCSV(pTable)"
      />
    </div>
  </div>

  <div class="max-w-[100vw] relative flexible">
    <p-table
      #pTable
      class="absolute inset-0 p-5 pt-0 overflow-y-scroll flexible"
      styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
      tableStyleClass="min-w-[10px]"
      selectionMode="single"
      [scrollable]="true"
      [columns]="columns"
      [value]="pTableData"
      [exportFilename]="table_name"
      [globalFilterFields]="['nombre', 'tipo_empresa', 'supervisor']"
      [paginator]="true"
      [alwaysShowPaginator]="true"
      [rows]="10"
      [rowsPerPageOptions]="[10, 25, 50]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Se muestran {last} de {totalRecords}"
    >
      <ng-template pTemplate="header">
        <tr>
          @for (column of columns; track $index) { @if ($index !==
          columns.length - 1) {
          <th [pSortableColumn]="column.field" class="w-[25%]">
            {{ column.header | titlecase }}
            <p-sortIcon [field]="column.field" />
          </th>
          } @else {
          <th class="auto">
            {{ column.header | titlecase }}
          </th>
          } }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-company>
        <tr>
          <td>{{ company.nombre }}</td>
          <td>{{ company.tipo_empresa }}</td>
          <td>{{ company.supervisor }}</td>
          <td class="flex items-center gap-x-1 custom-table-actions">
            <p-button
              styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
              icon="pi pi-inbox"
              (onClick)="unfileCompanyInfo(company)"
            />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="h-[60px] block sm:hidden"></div>

  <div
    class="absolute bottom-0 left-0 right-0 flex items-center justify-center w-full py-3 bg-white sm:hidden"
  >
    <p-button
      type="button"
      label="Descargar reporte"
      icon="pi pi-download"
      severity="danger"
      [rounded]="true"
      [outlined]="true"
    />
  </div>
</section>
