import { Location, TitleCasePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ApiCompanies } from '@api/api.companies'
import { pTableColumn, pTableCompany } from '@interfaces/p-table.interface'
import { ToastService } from '@services/toast.service'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { Table, TableModule } from 'primeng/table'
import { Company } from 'src/app/interfaces/companies.interface'
import { ApiService } from 'src/app/services/api.service'
import { CompaniesService } from 'src/app/services/companies.service'
import { TABLE_FILE_NAMES } from 'src/app/utils/p-table-names'

@Component({
  selector: 'app-companies-archived',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
    ButtonModule,
    TitleCasePipe,
  ],
  templateUrl: './companies-archived.component.html',
  styleUrl: './companies-archived.component.scss',
  host: {
    class: 'flexible',
  },
})
export class CompaniesArchivedComponent implements OnInit {
  // MARK: class members
  // table
  table_name = TABLE_FILE_NAMES['companies_archived']
  searchDate: Date | undefined
  columns: pTableColumn[] = [
    { field: 'nombre', header: 'empresa' },
    { field: 'tipo_empresa', header: 'tipo' },
    { field: 'supervisor', header: 'supervisor' },
    { field: 'acciones', header: 'acciones' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableCompany[] = []
  companies: Company[] = []

  constructor(
    private location: Location,
    private srvAPI: ApiService,
    private srvCompanies: CompaniesService,
    private srvToast: ToastService,
  ) {
    this.srvCompanies.companies$.subscribe((companies: any) => {
      this.companies = companies['archived']
      this.loadTable()
    })
  }

  ngOnInit() {
    this.srvCompanies.loadData()
  }

  loadTable() {
    this.pTableData = this.companies.map((item) => {
      return {
        _id: item._id,
        nombre: item.nombre,
        tipo_empresa: item.tipo_empresa.nombre,
        supervisor: item.supervisor,
      } as pTableCompany
    })
  }

  exportToCSV(pTable: Table) {
    const last = this.columns.pop()
    pTable.exportCSV()
    if (last) this.columns.push(last)
  }

  goBack() {
    this.location.back()
  }

  unfileCompanyInfo(company: Company) {
    this.srvAPI
      .patch(`${ApiCompanies.archivar}/${company._id}/0`, {})
      .subscribe({
        next: (response: any) => {
          this.srvToast.info({
            title: response.status,
            message: 'La empresa fue habilitada',
          })
          this.srvCompanies.loadData()
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }
}
